import { useState } from 'react';
import './Header.css';

const Header = ({ isMuted, toggleMute }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="horizontal-nav">
      <div className="burger-menu" onClick={toggleMenu}>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
      </div>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        
        <a href="https://raydium.io/swap/?outputMint=CRUCj2A5Ef7SvLufSBCQ2EAzrRp6JpHo8nh2iy9spump&inputMint=sol">Buy $LO</a>
        <a href="https://www.dextools.io/app/en/solana/pair-explorer/HxDyg3LC4DNusaXDM62dVL3gYXkGzsFj9nrUGcxvydPg?t=1717796026145" target="_blank" rel="noopener noreferrer">
          Dextools
        </a>
        <a href="https://dexscreener.com/solana/hxdyg3lc4dnusaxdm62dvl3gyxkgzsfj9nrugcxvydpg" target="_blank" rel="noopener noreferrer">
          Screener
        </a>
        <a href="https://t.me/+J9h9HqZ0IqE5Yzhh" target="_blank" rel="noopener noreferrer">
          Telegram
        </a>
        <a href="https://x.com/shirleylocoin" target="_blank" rel="noopener noreferrer">
          X
        </a>
        
       
       
      </div>
    </nav>
  );
}

export default Header;