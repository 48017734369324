import React, { useRef, useEffect } from 'react';

const MusicPlayer = ({ isPlaying }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <audio ref={audioRef} loop>
      <source src={`${process.env.PUBLIC_URL}/lovideo.mp4`} type="video/mp4" />
      Your browser does not support the audio element.
    </audio>
  );
}

export default MusicPlayer;