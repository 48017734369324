import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Header.js';
import ImagesDisplay from './ImageDisplay';
import MusicPlayer from './MusicPlayer';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const App = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [copyMessage, setCopyMessage] = useState('CRUCj2A5Ef7SvLufSBCQ2EAzrRp6JpHo8nh2iy9spump');

  const handleImageClick = (image) => {
    setPreviewImage(image);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText('CRUCj2A5Ef7SvLufSBCQ2EAzrRp6JpHo8nh2iy9spump');
    setCopyMessage('Copied!');
    setTimeout(() => setCopyMessage('CRUCj2A5Ef7SvLufSBCQ2EAzrRp6JpHo8nh2iy9spump'), 2000);
  };

  useEffect(() => {

    const createSparkles = () => {
      const sparklesContainer = document.querySelector('.sparkles');
      if (sparklesContainer) {
        for (let i = 0; i < 100; i++) {
          const sparkle = document.createElement('div');
          sparkle.className = 'sparkle';
          sparkle.style.top = `${Math.random() * 100}%`;
          sparkle.style.left = `${Math.random() * 100}%`;
          sparkle.style.animationDelay = `${Math.random() * 2}s`;
          sparklesContainer.appendChild(sparkle);
        }
      }
    };

    createSparkles();

    const handleFirstClick = () => {
      setIsPlaying(true);
      window.removeEventListener('click', handleFirstClick);
    };

    window.addEventListener('click', handleFirstClick);

    return () => {
      window.removeEventListener('click', handleFirstClick);
    };
  }, []);

  return (
    <div className="App">
      <div className="sparkles"></div>
      <MusicPlayer isPlaying={isPlaying} />
      <img src={`${process.env.PUBLIC_URL}/banner.jpg`} alt="Model" className="centered-image" />
      <Header />
      <ImagesDisplay />
      <div className="contract-address-title">$Lo </div>
      <div className="contract-address-email">Email: shirleylocoin@gmail.com</div>
      <div className="contract-address" onClick={handleCopy}>{copyMessage}</div>
      {previewImage && (
        <div className="image-preview" onClick={handleClosePreview}>
          <img src={`${process.env.PUBLIC_URL}/${previewImage}`} alt="Preview" />
        </div>
        
      )}
    </div>
  );
}

export default App;